/* eslint-disable */
import service from '@/utils/requset';

export function getUserInfo(params) {
  return service({
    url: '/api/b/nf/user/ticketInfo',
    method: 'post',
    params,
    loading: true,
    notReload: true,
  });
}

export function getUserOneTicketInfo(params) {
  return service({
    url: '/api/b/nf/user/ticketInfoLink',
    method: 'post',
    params,
    loading: true,
    notReload: true,
  })
}

export function getCarTicket(params) {
  return service({
    url: '/api/b/nf/car/ticket',
    method: 'post',
    params,
    loading: true
  })
}

export function getLangData(data) {
  return service({
    url: '/api/a/portal/display',
    method: 'post',
    data,
  })
}
